<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Welkom bij Roadpursuit`" :intro="intro" class="home">
        <div class="px-2">
            <v-container pt-0>
                <v-row no-gutters>
                    <v-col class="display-1 col-12 my-0 py-1">Roadtrips in {{getCurrentYear()}}</v-col>
                </v-row>
            </v-container>
        </div>
        <ImageDiv :imageSource="contentImage" :templateClass="'bannerImg'" />
        <div class="px-2 pb-4">
            <v-container>
                <v-row>
                    <v-col class="pb-0">
                        <div class = "pt-3">
                            {{roadtripintro}}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-card-actions class="justify-center">
                            <v-btn @click="goToMainCalendar()" block
                                color="primary">
                                Bekijk de planning
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="display-1 ma-0 pa-0">Galerij</p>
                        <div>{{galleryintro}}</div>
                    </v-col>
                    <v-col cols="12">
                        <v-card-actions class="justify-center">
                            <v-btn @click="goToGalleryAndReviews()" block
                                color="primary">
                                Bekijk de galerij
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate'
import ImageDiv from '@/components/common/ImageDiv'
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'Home',
    components: 
    {
        BannerTemplate,
        ImageDiv
    },
    data: () => ({
        bannerSource: require('@/assets/album/Home/home-01.jpg'),
        contentImage: require('@/assets/album/Home/home-02.jpg'),
        intro: "<p>Roadpursuit is het equivalent voor passie voor auto's, driving experience en omringing met gelijkgestemden. Hierbij staan avontuur, gezelligheid en verantwoord rijden bij ons centraal.</p><p>Roadpursuit biedt unieke roadtrips aan in Nederland en buitenland. Maak bijvoorbeeld een trip door de mooiste natuurgebieden in Nederland, een prachtige rondreis bij onze oosterburen of een avontuurlijke tour door de Zwitserse Alpen. Roadpursuit neemt eenieder mee op een mooie reis door natuur en cultuur op prachtige wegen.</p><p>Hebben wij uw interesse gewekt? Bekijk direct de planning (kalender) om deel te nemen aan één van onze trips.</p><div>Roadpursuit - Roadtrips for real drivers.</div>",
        roadtripintro: "Benieuwd welke roadtrips er voor dit jaar gepland staan? Bekijk de planning in onze kalender. U kunt hier per maand bekijken welke evenementen er allemaal gepland staan. Elk type rit (bijvoorbeeld een merkenrit) heeft z'n eigen kleur. Handig!",
        galleryintro: "In onze galerij kunt u alle foto's van voorgaande roadtrips terugvinden. Deze zijn gecategoriseerd op merk en vervolgens op roadtrip.",
    }),
    mixins: [RouteMixin, FormatMixin, MetaTagsMixin],
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
    },
    metaInfo() {
        return {
        meta: [
            this.getKeywords(`homepage, home, kalender, galerij, planning`),
            this.getMetaDescription(`The Roadpursuit experience... for real drivers. More than just roadtrips!`),
            this.getAuthor(),
            ...this.getSocialMediaMetas('Roadpursuit', 'Roadpursuit', `${window.location.origin}${this.bannerSource}`, 'Roadpursuit Home')
        ]
        };
    },
}
</script>
<style>

.home .bannerImg .v-image__image
{
    background-position: 50% 25% !important;
}

</style>
